import router from "@/router";
import dayjs from "dayjs";
function totalDataCaculate(data, kind) {
  if (kind === "purchase") {
    data.sumTotalQuantity = data
      .reduce((r, c) => r + (c.total_quantity ? c.total_quantity : 0), 0)
      .toFixed(0);
    data.sumTotalQuantityTruth = data
      .reduce((r, c) => r + (c.purchase.quantity ? c.purchase.quantity : 0), 0)
      .toFixed(0);
    const sumUnitPrice = data.reduce(
      (r, c) => r + (c.purchase.unit_price ? c.purchase.unit_price : 0),
      0,
    );
    data.avgUnitPrice = data.length
      ? (sumUnitPrice / data.length).toFixed(2)
      : 0;
    data.sumTotalAmount = data
      .reduce(
        (r, c) =>
          r +
          (c.purchase.eschamge_price
            ? +c.purchase.eschamge_price
            : c.purchase.total_amount
            ? +c.purchase.total_amount
            : 0),
        0,
      )
      .toFixed(0);
    data.total_count = data.length ? data.length : 0;
    return data;
  } else {
    data.sumTotalQuantity = data
      .reduce((r, c) => r + (c.purchase.quantity ? c.purchase.quantity : 0), 0)
      .toFixed(0);
    data.salesSumTotalQuantity = data
      .reduce((r, c) => r + (c.sales.quantity ? c.sales.quantity : 0), 0)
      .toFixed(0);
    const sumUnitPrice = data.reduce(
      (r, c) => r + (c.sales_unit_price ? c.sales_unit_price : 0),
      0,
    );
    data.avgUnitPrice = data.length
      ? (sumUnitPrice / data.length).toFixed(2)
      : 0;
    data.sumTotalAmount = data
      .reduce(
        (r, c) =>
          r +
          (c.sales.eschamge_price
            ? +c.sales.eschamge_price
            : c.sales.total_amount
            ? +c.sales.total_amount
            : 0),
        0,
      )
      .toFixed(0);
    data.total_count = data.length ? data.length : 0;
    return data;
  }
}

function dateFormat(date) {
  if (!date) {
    date = this.form.date_register;
  }
  const temp = new Date(date);
  const year = temp.getFullYear();
  const month = 1 + temp.getMonth();
  const day = temp.getDate();
  return `${year}-${month >= 10 ? month : "0" + month}-${
    day >= 10 ? day : "0" + day
  }`;
}
export default {
  setFormBackup(state, data) {
    state.form_backup = JSON.parse(JSON.stringify(data));
  },
  // auth
  setAuth(state, { company, uuid, token }) {
    state.auth_company = company;
    state.auth_uuid = uuid;
    state.auth_token = token;
  },
  setAuthCheck(state, { _id, name, grade }) {
    state.auth_id = _id;
    state.auth_name = name;
    state.auth_grade = grade;
  },
  clearAuth(state) {
    state.auth_company = "";
    state.auth_uuid = "";
    state.auth_token = "";
    state.auth_name = "";
    state.auth_grade = "";
  },
  // header_alert
  setHeaderAlert(state, data) {
    state.header_alert = data;
  },
  clearHeaderAlert(state) {
    state.header_alert = [];
  },
  setRouteAlert(state, { status, route }) {
    state.route_alert[route] = status;
  },
  setRouteAlertAll(state, status) {
    for (const value in state.route_alert) {
      state.route_alert[value] = status;
    }
  },
  // history
  setHistory(state, { company, uuid }) {
    const index = state.history_uuid.indexOf(uuid);
    if (index === -1) {
      state.history_company.push(company);
      state.history_uuid.push(uuid);
    }
  },
  removeHistory(state, index) {
    state.history_company.splice(index, 1);
    state.history_uuid.splice(index, 1);
  },
  clearHistory(state) {
    state.history_company = [];
    state.history_uuid = [];
  },
  // auth by history
  setAuthByHistory(state, { company, uuid }) {
    state.history_company_login = company;
    state.history_uuid_login = uuid;
  },
  clearAuthByHistory(state) {
    state.history_company_login = "";
    state.history_uuid_login = "";
  },
  //
  setAPILoading(state, data) {
    state.isAPILoading = data;
  },
  setDailogStatus(state, data) {
    state.isDialog = data.status;
    state.isKind = data.kind;
    state.attachmentId = data.id;
  },
  setIsPrintDialog(state, data) {
    state.isPrintDialog = data;
  },
  setDialogTitle(state, data) {
    state.dialogTitle = data;
  },
  setMiniStatus(state, data) {
    state.mini = data;
  },
  setOrderList(state, data) {
    state.orderList = data;
  },
  setResetCheck(state) {
    state.form_reset_check = !state.form_reset_check;
  },
  setItemList(state, data) {
    state.itemList = data;
  },
  setClientList(state, data) {
    state.clientList = data;
  },
  setUseClientList(state, data) {
    state.useClientList = data;
  },
  setUseItemList(state, data) {
    state.useItemList = data;
  },
  setUserList(state, data) {
    state.userList = data;
  },
  setCtypeList(state, data) {
    state.ctypeList = data;
  },
  setUseCtypeList(state, data) {
    state.useCtypeList = data;
  },
  setApprovalList(state, data) {
    state.approvalList = data;
  },
  setRequestReleaseList(state, data) {
    state.requestReleaseList = data ? data : [];
    state.defaultReleaseList = data ? data : [];
  },
  setForm(state, data) {
    state.form = data ? data : {};
  },
  // 오더 페이지
  setOrderForm(state, data) {
    state.orderForm = data
      ? data
      : {
          client: "",
          item: "",
          color_list: [],
          date_register: dateFormat(new Date(Date.now())),
          order_front_year: new Date(Date.now()).getFullYear() + "",
          order_front_season: "",
          unit_sign: "y",
          eschamge_sign: "₩",
          estimate_price_sign: "₩",
          sales_unit_price: "",
          memo_request: "",
          memo_confirm: "",
          sampleStatus: "",
          RepeatStatus: "",
          style_list: [],
          kind: 1,
        };
  },
  //approval
  setSaveApproval(state, data) {
    state.saveApproval = data ? data : {};
  },
  setApprovalForm(state, data) {
    state.approvalForm = data ? data : {};
  },
  setApprovalDialog(state, data) {
    state.approvalDialog = data;
  },
  setCompany(state, data) {
    state.company = data;
  },

  //수정 전 기존 데이터
  setBeforProductionData(state, data) {
    state.beforProductionData = data ? data : {};
  },
  //작성 데이터
  setProductionData(state, data) {
    state.productionData = data ? data : {};
    state.productionDialog = data !== undefined;
  },
  //가공, 출고의뢰서 color
  setProductionColor(state, data) {
    state.productionColor = data ? data : [];
  },

  setCommaColor(state, data) {
    state.commaColor = data ? data : [];
  },

  setColorTotal(state, total) {
    state.colorTotal = total;
  },
  setReleaseColorList(state, data) {
    state.releaseColorList = data ? data : [];
  },

  //bakData dialog open
  setBakData(state, data) {
    state.bakData = data ? data : { style: {}, color: {} };
    state.bakDataDialog = data !== undefined;
  },

  // 정산 페이지
  setChangeData(state, data) {
    state.changeData = data ? data : "";
  },
  setChangeDataDialog(state) {
    state.changeDialog = !state.changeDialog;
  },

  // 신규생성 다이얼로그 폼
  setEditedItemForm(state, data) {
    state.editedItem = data ? data : {};
  },

  setFiltered(state, data) {
    state.filters = data;
  },

  setSnackBar(state, text) {
    state.snackbar = text ? true : false;
    state.snackbar_text = text ? text : "";
  },
  setSelected(state, data) {
    state.selected = data ? data : [];
  },
  setAriseSelected(state, data) {
    state.ariseSelected = data ? data : [];
  },
  setPassSelected(state, data) {
    state.passSelected = data ? data : [];
  },
  setSalesVatData(state, data) {
    state.vatSalesData = data ? data : {};
  },

  //생산페이지 시작 (production page) ----------------------------------------
  // 오더폼 set
  setProductionOrderForm(state, order) {
    state.saveData = order ? JSON.parse(JSON.stringify(order)) : {};
    state.productionOrderForm = order ? order : {};
  },

  // order form doc List
  setProductionDoc(state, list) {
    state.productionDocList = list ? JSON.parse(JSON.stringify(list)) : [];
  },

  // 가공의뢰서 form set
  setManufactureForm(state, manufacture) {
    state.manufactureForm = manufacture
      ? manufacture
      : { dialog: false, order: "" };
  },
  // 출고의뢰서 form set
  setReleaseForm(state, release) {
    state.releaseForm = release
      ? release
      : {
          dialog: false,
          order: "",
          salesBtn: false,
          appenBtn: false,
          requestDoc: { kind: 8 },
        };
  },
  // 가공의뢰서 form set
  setAppearanceForm(state, apperance) {
    state.appearanceForm = apperance
      ? apperance
      : { dialog: false, order: "", color_list: [] };
  },

  setProductionConfirm(state) {
    state.confirmDialog = !state.confirmDialog;
  },

  setProductionProgress(state, data) {
    state.productionProgress = data ? data : {};
  },

  setCancelDialog(state, data) {
    state.cancelDialog = data ? data : { dialog: false, status: "", kind: "" };
  },

  //저장 데이터
  setSaveReleaseColor(state, data) {
    state.saveReleaseColor = data ? data : {};
  },

  setAppearanceList(state) {
    let appearances = state.productionOrderForm.release.filter(
      (x) => x.kind === 10 && x.status === "40",
    );
    appearances = JSON.parse(JSON.stringify(appearances)).map((doc, i) => {
      const client = state.clientList.find((x) => x._id === doc.place_forward);
      doc.forwardName = `${i + 1}_${client.name}`;
      return doc;
    });

    state.requsestAppearance = appearances;
  },

  setSelectOrder(state, order) {
    state.selectOrder = order ? order : {};
  },

  setRelApprovalList(state, data) {
    state.relApprovalList = data ? data : "";
  },
  //생산페이지 끝 (production page) -----------------------------------------

  setSaveData(state, data) {
    state.saveData = data ? data : "";
  },

  //승인요청 다이어로그 시작  -----------------------------------------
  setRequestDialog(state) {
    state.requestDialog = !state.requestDialog;
  },
  setUserRequestDialog(state) {
    state.userRequesDialog = !state.userRequesDialog;
  },
  setRequestList(state, requestList) {
    state.requestList = requestList ? requestList : [];
  },

  setRequestForm(state, form) {
    state.requestForm = form ? form : {};
  },

  setRequestSetList(state, data) {
    state.requestSetList = data ? data : { name: "order", list: [], kind: 1 };
  },
  //승인요청 다이어로그 끝  ------------------------------------------
  // 클레임 페이지
  setClaimForm(state, data) {
    state.claimForm = data
      ? data
      : {
          currency_sign: "₩",
          detail: "",
        };
  },
  setClaimPassRows(state, data) {
    state.claimPassRows = data
      ? data
      : {
          currency_sign: "₩",
          detail: "",
        };
  },
  setClaimNewRows(state, data) {
    state.claimNewRows = data ? data : {};
  },
  clearClaimNewRows(state, data) {
    state.claimNewRows = data
      ? data
      : {
          arise_month: dayjs().format("YYYY-MM"),
          arise_currency_sign: "₩",
          arise_detail: "협의중|",
          arise_passtype: 1,
          pass_month: dayjs().format("YYYY-MM"),
          pass_currency_sign: "₩",
          pass_detail: "",
        };
  },
  setDefaultFilterData(state, data) {
    state.defaultFilterData = data ? data : {};
  },

  // 정산 페이지
  setSaveRecivable(state, data) {
    state.saveRecivable = data ? data : {};
  },

  //프린트 다이어로그  -------------------------------------------
  setManufacturePrient(state, data) {
    state.manufacturePrient = data ? data : { dialog: false };
  },
  setReleasePrient(state, data) {
    state.releasePrient = data ? data : { dialog: false };
  },
  setPrientColor(state, data) {
    state.prientColor = data ? data : [];
  },
  //프린트 다이어로그 끝 ------------------------------------------
  // 프린트
  setPrintForm(state, data) {
    state.printForm = data ? data : {};
  },
  setPrintType(state, data) {
    state.printType = data ? data : "";
  },
  //프린트 이메일
  setEmailForm(state, data) {
    state.emailForm = data ? data : {};
  },
  setEmailInput(state, data) {
    state.emailInput = data ? data : {};
  },
  //임시 필터링용
  setFilterData(state, data) {
    state.activeFilters = data ? data.activeFilters : [];
    state.numsFilters = data ? data.numsFilters : [];
    state.filters = data ? data.filters : [];
    state.purposeFilters = data ? data.purposeFilters : [];
  },
  setPurchaseFilterData(state, data) {
    state.purActiveFilters = data ? data.purActiveFilters : [];
    state.purNumsFilters = data ? data.purNumsFilters : [];
    state.purFilters = data ? data.purFilters : [];
    state.purPurposeFilters = data ? data.purPurposeFilters : [];
  },
  setEndClaimFilterData(state, data) {
    state.endActiveFilters = data ? data.endActiveFilters : [];
    state.endNumsFilters = data ? data.endNumsFilters : [];
    state.endFilters = data ? data.endFilters : [];
    state.endPurposeFilters = data ? data.endPurposeFilters : [];
  },
  setFilteringData(state, data) {
    state.activeFilters = data ? data : {};
  },
  setFilterItem(state, data) {
    state.filterItem = data ? data : {};
  },
  seTotalData(state, data) {
    state.totalData = data ? data : {};
  },

  setPurchaseCacList(state, data) {
    state.purchaseCacList = data ? data : [];
  },
  setClaimOriginalList(state, data) {
    state.claimOriginalList = data ? data : [];
  },
  setClaimFilterList(state, data) {
    state.claimFilterList = data ? data : [];
  },
  setEndClaimOriginalList(state, data) {
    state.endClaimOriginalList = data ? data : [];
  },
  setEndClaimFilterList(state, data) {
    state.endClaimFilterList = data ? data : [];
  },
  setClaimOriginFilter(state, data) {
    state.claimOriginFilter = data ? data : [];
  },
  setClaimOriginTotalList(state, data) {
    state.claimOriginTotalList = data ? data : [];
  },
  setClaimFilteredOrginList(state, data) {
    state.claimFilteredOrginList = data ? data : [];
  },
  setEndClaimOriginFilter(state, data) {
    state.endClaimOriginFilter = data ? data : [];
  },
  setEndClaimOriginTotalList(state, data) {
    state.endClaimOriginTotalList = data ? data : [];
  },
  setEndClaimFilteredOrginList(state, data) {
    state.endClaimFilteredOrginList = data ? data : [];
  },

  // filter  -------------------------------------------
  setOriginFilter(state, data) {
    state.originFilter = data;
  },

  //기존 필터링
  setExistingFilter(state, data) {
    state.existingFilter = data ? data : "";
  },

  setMainOriginList(state, data) {
    state.mainOriginList = data;
  },
  setMainFilterList(state, data) {
    state.mainFilterList = data;
  },
  setMainOriginFilter(state, data) {
    state.mainOriginFilter = data;
  },
  setMainOriginTotalList(state, data) {
    state.mainOriginTotalList = data;
  },
  //order page

  setOrderPageOrigin(state, data) {
    state.orderPageOrigin = data;
  },
  setOrderPageFilter(state, data) {
    state.orderPageFilter = data;
  },
  setOrderOriginFilter(state, data) {
    state.orderOriginFilter = data;
  },
  setOrderOriginTotalList(state, data) {
    state.orderOriginTotalList = data;
  },
  setOrderFilteredOrginList(state, data) {
    state.orderFilteredOrginList = data;
  },

  // production page

  setProductionOriginList(state, data) {
    state.productionOriginList = data;
  },
  setProductionFilterList(state, data) {
    state.productionFilterList = data;
  },
  setProductionOriginFilter(state, data) {
    state.productionOriginFilter = data;
  },
  setProductionOriginTotalList(state, data) {
    state.productionOriginTotalList = data;
  },
  setProductionFilteredOrginList(state, data) {
    state.productionFilteredOrginList = data;
  },

  setOriginVatList(state, data) {
    state.originVatList = data;
  },
  setVatFilterList(state, data) {
    state.vatFilterList = data;
  },
  setVatOriginFilter(state, data) {
    state.vatOriginFilter = data;
  },
  setVatOriginTotalList(state, data) {
    state.vatOriginTotalList = data;
  },
  setVatFilteredOrginList(state, data) {
    state.vatFilteredOrginList = data ? data : [];
  },

  setSalesOriginVatList(state, data) {
    state.salesOriginVatList = data;
  },
  setSalesVatFilterList(state, data) {
    state.salesVatFilterList = data;
  },
  setSalesVatOriginFilter(state, data) {
    state.salesVatOriginFilter = data ? data : [];
  },
  setSalesVatOriginTotalList(state, data) {
    state.salesVatOriginTotalList = data;
  },
  setSalesVatFilteredOrginList(state, data) {
    state.salesVatFilteredOrginList = data ? data : [];
  },

  //매입 정산 페이지
  setPurchCalculList(state, data) {
    state.purchCalculList = data;
  },
  setPurchCalculOrigin(state, data) {
    state.purchCalculOrigin = data;
  },
  setPurchCalculFilter(state, data) {
    state.purchCalculFilter = data;
  },
  setPurchaseCacOriginFilter(state, data) {
    state.purchaseCacOriginFilter = data ? data : [];
  },
  setPurchaseCacOriginTotalList(state, data) {
    state.purchaseCacOriginTotalList = data;
  },
  setPurchaseCacFilteredOrginList(state, data) {
    state.purchaseCacFilteredOrginList = data ? data : [];
  },

  //매출 정산 페이지
  setSalesCacList(state, data) {
    state.salesCacList = data;
  },
  setSalesCacOrigin(state, data) {
    state.salesCacOrigin = data;
  },
  setSalesCacFilter(state, data) {
    state.salesCacFilter = data;
  },
  setSalesCaccOriginFilter(state, data) {
    state.salesCaccOriginFilter = data;
  },
  setSalesCacOriginTotalList(state, data) {
    state.salesCacOriginTotalList = data;
  },
  setSalesCacFilteredOrginList(state, data) {
    state.salesCacFilteredOrginList = data ? data : [];
  },

  //매출 마감 페이지
  setSalesEndList(state, data) {
    state.salesEndList = data;
  },
  setSalesEndOrigin(state, data) {
    state.salesEndOrigin = data;
  },
  setSalesEndFilter(state, data) {
    state.salesEndFilter = data;
  },
  setSalesDedOriginFilter(state, data) {
    state.salesDedOriginFilter = data;
  },
  setSalesDedOriginTotalList(state, data) {
    state.salesDedOriginTotalList = data;
  },
  setSalesDedFilteredOrginList(state, data) {
    state.salesDedFilteredOrginList = data ? data : [];
  },

  //매입 마감 페이지
  setPurchSalesCalculList(state, data) {
    state.purchSalesCalculList = data;
  },
  setPurchSalesCalculOrigin(state, data) {
    state.purchSalesCalculOrigin = data;
  },
  setPurchSalesCalculFilter(state, data) {
    state.purchSalesCalculFilter = data;
  },
  setPurchaseDedcOriginFilter(state, data) {
    state.purchaseDedcOriginFilter = data;
  },
  setPurchaseDedOriginTotalList(state, data) {
    state.purchaseDedOriginTotalList = data;
  },
  setPurchaseDedFilteredOrginList(state, data) {
    state.purchaseDedFilteredOrginList = data ? data : [];
  },

  setNewOrderNo(state, data) {
    state.newOrderNo = data
      ? data
      : {
          order: "",
          order_back: "",
        };
  },
  setNumFilterList(state, data) {
    state.numFilterList = data ? data : [];
  },
  setNumFilteringList(state, data) {
    state.numFilteringList = data ? data : [];
  },
  // filter 끝  -------------------------------------------
  // sigup 시쟉  -------------------------------------------
  // signType 저장
  setSignType(state, data) {
    state.signType = data ? data : 0;
  },

  // sigup 끝  -------------------------------------------

  // ExcelList
  setExcelOrderList(state, data) {
    state.excelOrderList = data;
  },
  setExcelMainOrderList(state, data) {
    state.excelMainOrderList = data;
  },
  setExcelPurchaseList(state, data) {
    state.excelPurchaseList = data;
  },
  setExcelSalesList(state, data) {
    state.excelSalesList = data;
  },
  setExcelClaimList(state, data) {
    state.excelClaimList = data;
  },
  setExcelPurVatList(state, data) {
    state.excelPurVatList = data;
  },
  setExcelSalesVatList(state, data) {
    state.excelSalesVatList = data;
  },
  setApprovalMemo(state, data) {
    state.approvalMemo = data ? data : "";
  },
  setUserAlert(state, data) {
    state.userAlert = data ? data : 0;
  },

  setClaimModifyForm(state, data) {
    state.claimModifyForm = data ? data : {};
  },
  // setClaimEdit(state, data) {
  //   state.claimEdit = data ? data : {};
  // },
  setClaimReleaseForm(state, data) {
    state.claimReleaseForm = data ? data : {};
  },
  setOtherClaimReleaseForm(state, data) {
    state.otherClaimReleaseForm = data ? data : {};
  },
  setSelectClaim(state, data) {
    state.selectClaim = data ? data : {};
  },
  setSelectClaimList(state, data) {
    state.selectClaimList = data ? data : [];
  },
  setBeforeOrder(state, data) {
    state.beforeOrder = data ? data : {};
  },
  setAfterOrder(state, data) {
    state.afterOrder = data ? data : {};
  },
  setAriseCheckBox(state, data) {
    state.ariseCheckBox = data;
  },
  setPassCheckBox(state, data) {
    state.passCheckBox = data;
  },
  setEndAriseCheckBox(state, data) {
    state.endAriseCheckBox = data;
  },
  setEndPassCheckBox(state, data) {
    state.endPassCheckBox = data;
  },

  //생산페이지 매입 매출 계산 값
  setPurchasCacul(state, data) {
    state.purchasCacul = data ? data : {};
  },
  setSalesCacul(state, data) {
    state.salesCacul = data ? data : {};
  },
  setExcelFormList(state, data) {
    state.excelFormList = data ? data : [];
  },
  setItemExcelFormList(state, data) {
    state.itemExcelFormList = data ? data : [];
  },
  setCtypeExcelFormList(state, data) {
    state.ctypeExcelFormList = data ? data : [];
  },
  setWindow(state, data) {
    state.window = data ? data : {};
  },
  // 가공/출고 의뢰서 문서 열람 기능
  setDocData(state, data) {
    state.docData = data ? data : {};
  },
  setSelectDocData(state, data) {
    state.selectDocData = data ? data : { kind: 0 };
  },
  setIsDocDataBtn(state) {
    state.isDocDataBtn = !state.isDocDataBtn;
  },
  setDocDataList(state, data) {
    state.docDataList = data ? data : [];
  },
  setContextData(state, data) {
    state.contextData = data ? data : {};
  },
  setTargetScroll(state, data) {
    state.targetScroll = data ? data : { target: "", status: false };
  },
  setContextTargetList(state, data) {
    state.contextTargetList = data ? data : [];
  },
  setLoginForm(state, data) {
    state.loginForm = data ? data : "login";
  },
  setClaimView(state, data) {
    state.claimView = data;
  },
  setNavSelected(state, data) {
    state.navSelected = data;
  },
  setFormDateOrder(state, data) {
    state.formDateOrder = data;
  },
  setEditCompany(state, data) {
    state.editCompany = data ? data : {};
  },

  setEditRowData(state, data) {
    state.editRowData = data
      ? data
      : {
          status: false,
          target: "",
          index: [null, null],
          value: "",
          value2: "",
          type: "",
        };
  },

  setBakDocListDialog(state) {
    state.bakDocListDialog = !state.bakDocListDialog;
  },
  setSaveRecivableAmount(state, data) {
    state.saveRecivableAmount = data ? data : {};
  },
  setErrorStatus(state, data) {
    state.errorStatus = data;
  },

  setNumDialogData(state, data) {
    state.numDialogData = data ? data : {};
  },

  setAttachmentList(state, data) {
    state.attachmentList = data ? data : [];
  },

  setLogoImg(state, data) {
    state.logoImg = data ? data : {};
  },
  setEmailFile(state, data) {
    state.emailFile = data ? data : {};
  },
  setAuthLogo(state, data) {
    state.auth_logo = data ? data : {};
  },

  setSaveStock(state, data) {
    state.saveStock = data ? data : [];
  },

  setSelectStock(state, data) {
    state.selectStock = data ? data : [];
  },
  setDeleteStock(state, data) {
    state.deleteStock = data ? data : [];
  },
  setStockList(state, data) {
    state.stockList = data ? data : [];
  },
  setIsSalesSurcharge(state) {
    state.isSalesSurcharge = !state.isSalesSurcharge;
  },
  setRequestApprovalList(state, data) {
    state.requestApprovalList = data ? data : [];
  },
  setRequestApprovalOriginalList(state, data) {
    state.requestApprovalOriginalList = data ? data : [];
  },
  setRequestApprovalFilterList(state, data) {
    state.requestApprovalFilterList = data ? data : [];
  },
  setRequestApprovaloriginFilter(state, data) {
    state.requestApprovaloriginFilter = data ? data : [];
  },
  setStockColorList(state, data) {
    state.stockColorList = data ? data : [];
  },

  //재고페이지

  setStockTableOriginList(state, data) {
    state.stockTableOriginList = data ? data : [];
  },
  setStockTableFilterList(state, data) {
    state.stockTableFilterList = data ? data : [];
  },
  setStockOriginFilter(state, data) {
    state.stockOriginFilter = data ? data : [];
  },
  setStockOriginTotalList(state, data) {
    state.stockOriginTotalList = data ? data : [];
  },
  setStockFilteredOrginList(state, data) {
    state.stockFilteredOrginList = data ? data : [];
  },
  setNewStock(state, data) {
    state.newStock = data
      ? data
      : {
          unit_sign: "y",
          date: dateFormat(new Date()),
          currency_sign: "₩",
        };
  },
  setEditStockForm(state, data) {
    state.editStockForm = data ? data : {};
  },
  setIsStockDialog(state) {
    state.isStockDialog = !state.isStockDialog;
  },
  setIsProgressDialog(state) {
    state.isProgressDialog = !state.isProgressDialog;
  },

  setProgressData(state, data) {
    state.progressData = data ? data : {};
  },

  setProgressFileList(state, data) {
    state.prgoressFileList = data ? data : [];
  },

  setPresetList(state, data) {
    state.presetList = data ? data : [];
  },

  setPageIndex(state, data) {
    state.pageIndex = data ? data : 1;
  },
  setStockView(state, data) {
    state.stockView = data;
  },
  setSecondSaveData(state, data) {
    state.secondSaveData = data ? data : "";
  },
  setUsedStockList(state, data) {
    state.usedStockList = data ? data : [];
  },
  setInputSetStockList(state, data) {
    state.inputSetStockList = data ? data : [];
  },
  setOutputSetStockList(state, data) {
    state.outputSetStockList = data ? data : [];
  },

  setDocReferanceGroup(state, data) {
    state.docReferanceGroup = data ? data : [];
  },
  setFabricCaculateDialog(state) {
    state.fabricCaculateDialog = !state.fabricCaculateDialog;
  },
  setApplyFilterList(state, data) {
    if (data) state.applyFilterList[data.route] = data.filter;
    else state.applyFilterList = {};
  },
  setPageTotalSize(state, data) {
    state.pageTotalSize = data ? data : null;
  },
  setOriginPageTotalSize(state, data) {
    state.originPageTotalSize = data ? data : null;
  },
  setInitScroll(state, data) {
    state.initScroll = data ? data : "";
  },
  setMainFilteredOrginList(state, data) {
    state.mainFilteredOrginList = data ? data : [];
  },
  setApplySortList(state, data) {
    state.applySortList = data ? data : [];
  },

  /**
   * 1. 오더 페이지 mutations
   */
  // order 페이지 list
  setOrderPageList(state, data) {
    state.orderPageList = data ?? [];
  },
  // order 페이지 InfiniteSroll data Set
  setOrderPageListInfinite(state, data) {
    state.orderPageList = [...state.orderPageList, ...data];
  },

  // 오더 페이지 검색 params 변경
  setOrderParams(state, data) {
    state.orderParams = data ?? [];
  },
  //오더 페이지 total Count 변경
  setOrderTotalCount(state, data) {
    state.orderTotalCount = data;
  },

  // 오더 페이지 sort
  setOrderSort(state, sort) {
    state.orderSort = sort;
  },

  /**
   * 2. 생산 페이지 mutations
   */
  // 생산 페이지 List
  setProductionOrderList(state, data) {
    state.productionOrderList = data;
  },
  // 생산 페이지 InfiniteSroll data Set
  setProductionPageListInfinite(state, data) {
    state.productionOrderList = [...state.productionOrderList, ...data];
  },
  // 생산 페이지 검색 params 변경
  setProductionParams(state, data) {
    state.productionParams = data ?? [];
  },
  //생산 페이지 total Count 변경
  setProductionTotalCount(state, data) {
    state.productionTotalCount = data;
  },

  // 생산 페이지 sort
  setProductionSort(state, sort) {
    state.productionSort = sort;
  },

  /**
   * 3. 정산 페이지 mutations
   */
  // 매입 정산 리스트 변경
  setCalculates(state, data) {
    state.calculates = data ? data : [];
  },
  // 매입 정산 리스트 무한 스크롤
  setCalculatesInfinite(state, data) {
    state.calculates = [...state.calculates, ...data];
  },

  // 매입 마감 리스트 변경
  setCalculateEnds(state, data) {
    state.calculateEnds = data ? data : [];
  },
  // 매입 마감 리스트 변경
  setCalculateEndsInfinite(state, data) {
    state.calculateEnds = [...state.calculateEnds, ...data];
  },

  // 매출 정산 리스트 변경
  setSalesCalculates(state, data) {
    state.salesCalculates = data ? data : [];
  },
  // 매출 정산 리스트 변경
  setSalesCalculatesInfinite(state, data) {
    state.salesCalculates = [...state.salesCalculates, ...data];
  },

  // 매출 마감 리스트 변경
  setSalesCalculateEnds(state, data) {
    state.salesCalculateEnds = data ? data : [];
  },
  // 매출 마감 리스트 변경
  setSalesCalculateEndsInfinite(state, data) {
    state.salesCalculateEnds = [...state.salesCalculateEnds, ...data];
  },

  // 매입 정산 페이지 params 변경
  setPurchaseCaculParam(state, data) {
    state.purchaseCaculParam = data ?? [];
  },
  // 매입 마감 페이지 params 변경
  setPurchaseEndParam(state, data) {
    state.purchaseEndParam = data ?? [];
  },
  // 매입 정산 페이지 params 변경
  setSalesCaculParam(state, data) {
    state.salesCaculParam = data ?? [];
  },
  // 매입 정산 페이지 params 변경
  setSalesEndParam(state, data) {
    state.salesEndParam = data ?? [];
  },
  // 정산, 마감 페이지 total
  setCalculateTotal(state, data) {
    state.calculateTotal = data;
  },

  // 매입 정산 페이지 sort
  setCalculatesSort(state, sort) {
    state.calculatesSort = sort;
  },
  // 매입 마감 페이지 sort
  setCalculateEndsSort(state, sort) {
    state.calculateEndsSort = sort;
  },
  // 매출 정산 페이지 sort
  setSalesCalculatesSort(state, sort) {
    state.salesCalculatesSort = sort;
  },
  // 매출 마감 페이지 sort
  setSalesCalculateEndSort(state, sort) {
    state.salesCalculateEndSort = sort;
  },

  // 매입 정산 checkBox
  setPurCheckBox(state, data) {
    state.purchCheckBox = data;
  },
  // 매입 마감 정산 check box
  setEndPurCheckBox(state, data) {
    state.endPurchCheckBox = data;
  },
  setSalesCheckBox(state, data) {
    state.salesCheckBox = data;
  },
  setEndSalesCheckBox(state, data) {
    state.endSalesCheckBox = data;
  },

  /**
   * 4 클레임 페이지 setter
   */
  // 클레임 리스트
  setClaimList(state, data) {
    state.claimList = data ? data : [];
  },
  setClaimListInfinite(state, data) {
    state.claimList = [...state.claimList, ...data];
  },

  // end 클레임 리스트
  setEndClaimList(state, data) {
    state.endClaimList = data ? data : [];
  },
  // end 클레임 리스트
  setEndClaimListInfinite(state, data) {
    state.endClaimList = [...state.endClaimList, ...data];
  },

  // 클레임 param
  setClaimParam(state, data) {
    state.claimParam = data ?? [];
  },
  //end 클레임 param
  setEndClaimParam(state, data) {
    state.endClaimParam = data ?? [];
  },
  //getClaimTotal
  // 정산, 마감 페이지 total Count
  setClaimTotal(state, data) {
    state.claimTotal = data;
  },
  // claim 페이지 sort
  setClaimSort(state, sort) {
    state.claimSort = sort;
  },
  // claim 페이지 sort
  setEndClaimSort(state, sort) {
    state.endClaimSort = sort;
  },

  setAriseAllChecked(state, data) {
    state.ariseAllChecked = data;
  },

  setPassAllChecked(state, data) {
    state.passAllChecked = data;
  },

  setEndAriseAllChecked(state, data) {
    state.endAriseAllChecked = data;
  },

  setEndPassAllChecked(state, data) {
    state.endPassAllChecked = data;
  },

  /**
   * 6 main 페이지 setter
   */
  //main Page
  setMainOrderList(state, data) {
    state.mainOrderList = data ?? [];
  },
  // main 페이지 InfiniteSroll data Set
  setMainPageListInfinite(state, data) {
    state.mainOrderList = [...state.mainOrderList, ...data];
  },
  // 메인 param
  setMainParam(state, data) {
    state.mainParam = data ?? [];
  },
  // 메인 total
  setMainTotal(state, data) {
    state.mainTotal = data;
  },
  // main 페이지 sort
  setMainSort(state, sort) {
    state.mainSort = sort;
  },

  /**
   * 6 외상금 페이지 setter
   */
  //외상 매입 페이지
  setVatList(state, data) {
    state.vatList = data ? data : [];
  },
  //외상 매입 페이지
  setVatListInfinite(state, data) {
    state.vatList = [...state.vatList, ...data];
  },
  //외상 매출 페이지
  setVatSalesList(state, data) {
    state.vatSalesList = data ?? [];
  },
  //외상 매출 페이지
  setVatSalesListInfinite(state, data) {
    state.vatSalesList = [...state.vatSalesList, ...data];
  },
  //매입 외상금 param
  setRecivableParam(state, data) {
    state.purchaseVatParam = data ?? [];
  },
  //매출 외상금 param
  setSalesRecivableParam(state, data) {
    state.salesVatParam = data ?? [];
  },
  //외상금 total
  setRecivableTotal(state, data) {
    state.vatTotal = data;
  },

  setVatSort(state, sort) {
    state.vatSort = sort;
  },
  setVatSalesSort(state, sort) {
    state.vatSalesSort = sort;
  },

  /**
   * 7 재고 페이지 getters
   */
  //재고 페이지 리스트
  setStockTableList(state, data) {
    state.stockTableList = data ? data : [];
  },
  setUseStockClientList(state, data) {
    state.useStockClientList = data ? data : [];
  },
  //재고 페이지
  setStockListInfinite(state, data) {
    state.stockTableList = [...state.stockTableList, ...data];
  },

  //재고 페이지 param
  setStockParam(state, data) {
    state.stockParam = data ?? [];
  },

  //재고 페이지 total
  setStockTotal(state, data) {
    state.stockTotal = data ?? [];
  },

  setStockSort(state, sort) {
    state.stockSort = sort;
  },

  // 재고
  setStockCheckbox(state, data) {
    state.stockCheckbox = data;
  },

  /**
   * checkbox length
   */
  setCheckBoxLength(state, data) {
    state.checkBoxLength = data;
  },
  setMainChartData(state, data) {
    state.mainChartData = data;
  },
  setIsPreviewDialog(state, data) {
    state.isPreviewDialog = !state.isPreviewDialog;
  },
  setPreviewImage(state, data) {
    state.previewImage = data ?? "";
  },
  setRequestFormDialog(state) {
    state.requestFormDialog = !state.requestFormDialog;
  },
  setApprvoalHistory(state, data) {
    state.apprvoalHistory = data ? data : [];
  },
  setRequestType(state, data) {
    state.requestType = data;
  },
  setIsExpired(state, data) {
    state.isExpired = data;
  },
  setIsPayment(state, data) {
    state.isPayment = data;
  },
  setPaymentList(state, data) {
    state.paymentList = data ? data : [];
  },
  setTrialAlramDialog(state, data) {
    state.trialAlramDialog = !state.trialAlramDialog;
  },
  setTrialClose(state, data) {
    state.trialClose = data;
  },
  setAdminConfirmDialog(state) {
    state.adminConfirmDialog = !state.adminConfirmDialog;
  },
  setPayData(state, data) {
    state.payData = data ? data : {};
  },
  setAdminPaymentList(state, data) {
    state.adminPaymentList = data ? data : [];
  },
  setMembershipInfo(state, data) {
    state.membershipInfo = data ? data : {};
  },
  setExcelUploadForm(state, data) {
    state.excelUploadForm = data ?? [];
  },
  setCompanySetting(state, data) {
    state.companySetting = data ?? {
      print_type: null,
    };
  },
};
