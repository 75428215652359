export default [
  {
    value: "checkBox",
    width: "2%",
  },
  {
    text: "구분",
    value: "kind",
    width: "3%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "년도",
    value: "year",
    width: "3%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
    sort: -1,
  },
  {
    text: "월",
    value: "month",
    width: "3%",
    align: "center",
    class: "tableHeaderBody",
    type: "date",
    dateType: "mm",
    filterable: true,
    sort: -1,
  },
  {
    text: "시즌",
    value: "season",
    width: "3%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "오더",
    value: "order",
    width: "5%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
    sort: -1,
  },
  {
    text: "발주처",
    value: "buyer",
    width: "7%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "아이템",
    value: "item",
    width: "7%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "거래처",
    value: "placeForward",
    width: "7%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "컬러",
    value: "color",
    width: "6%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "매출 월",
    value: "salesMonth",
    width: "3%",
    align: "center",
    class: "tableHeaderBody",
    groupable: true,
    filterable: true,
    type: "date",
    dateType: "mmdd",
    sort: -1,
  },
  {
    text: "매출 수량",
    value: "salesQuantity",
    width: "4.5%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
    type: "number",
  },
  {
    text: "출고 수량",
    value: "totalQuantity",
    width: "4.5%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
    type: "number",
  },
  {
    text: "매입 수량",
    value: "purchaseQuantity",
    width: "4.5%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
    type: "number",
  },
  {
    text: "단가",
    value: "purchaseUnitPrice",
    width: "6%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
    type: "number",
  },
  {
    text: "금액",
    value: "amount",
    width: "6%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
    type: "number",
  },
  {
    text: "Surcharge(염색, 건 당)",
    value: "colorSurchargePrice",
    width: "6%",
    align: "center",
    class: "tableHeaderBody text-overflow",
    filterable: true,
    type: "number",
  },
  {
    text: `Surcharge(제직, 건 당)`,
    value: "surchargePrice",
    width: "6%",
    align: "center",
    class: "tableHeaderBody text-overflow",
    filterable: true,
    type: "number",
  },
  {
    text: "금액 합",
    value: "totalAmount",
    width: "6%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
    type: "number",
  },
  {
    text: "상태",
    width: "3%",
    value: "status",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "비고",
    value: "memo",
    width: "8%",
    align: "center",
    class: "tableHeaderRigth",
    filterable: false,
  },
];
