export default [
  {
    text: " ",
    value: "checkbox",
    width: "2%",
    align: "center",
    class: "tableHeaderBody",
    filterable: false,
  },
  {
    text: "구분",
    value: "kind",
    width: "3%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "년도",
    value: "year",
    width: "2%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
    type: "date",
    sort: -1,
  },
  {
    text: "월",
    value: "month",
    width: "3%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
    type: "date",
    dateType: "dd",
    sort: -1,
  },
  {
    text: "시즌",
    value: "season",
    width: "2%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "오더",
    value: "order",
    width: "5%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
    sort: -1,
  },
  {
    text: "발주처",
    value: "buyer",
    width: "6%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "아이템",
    value: "item",
    width: "6%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "거래처",
    value: "placeForward",
    width: "6%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "컬러",
    value: "color",
    width: "5%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "매입 월",
    value: "purchaseMonth",
    width: "3%",
    align: "center",
    class: "tableHeaderBody",
    groupable: true,
    filterable: true,
    type: "date",
    dateType: "mmdd",
    sort: -1,
  },
  {
    text: "매입 수량",
    value: "purchaseQuantity",
    width: "4%",
    align: "center",
    class: "tableHeaderBody",
    groupable: true,
    filterable: true,
    type: "number",
  },
  {
    text: "출고 수량",
    value: "totalQuantity",
    width: "4%",
    align: "center",
    class: "tableHeaderBody",
    groupable: true,
    filterable: true,
    type: "number",
  },
  {
    text: "매출처",
    value: "placeSales",
    width: "6%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "매출 수량",
    value: "salesQuantity",
    width: "4%",
    align: "center",
    class: "tableHeaderBody",
    groupable: true,
    filterable: true,
    type: "number",
  },
  {
    text: "매출 단가",
    value: "salesUnitPrice",
    width: "5%",
    align: "center",
    class: "tableHeaderBody",
    groupable: true,
    filterable: true,
    type: "number",
  },
  {
    text: "컬러 Surcharge",
    value: "colorSurchargeUnit",
    width: "5%",
    align: "center",
    class: "tableHeaderBody",
    groupable: true,
    filterable: true,
    type: "number",
  },
  {
    text: "건 당 Surcharge",
    value: "salesSurchargeUnit",
    width: "5%",
    align: "center",
    class: "tableHeaderBody",
    groupable: true,
    filterable: true,
    type: "number",
  },
  {
    text: "매출 금액",
    value: "salesTotalAmount",
    width: "5%",
    align: "center",
    class: "tableHeaderBody",
    groupable: true,
    filterable: true,
    type: "number",
  },
  {
    text: "상태",
    value: "status",
    width: "3%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "비고",
    value: "memo",
    width: "8%",
    align: "center",
    class: "tableHeaderRigth",
    filterable: false,
  },
];
