import store from "@/store";
import { instance } from "./index";

function getUserByCompany() {
  return instance.get(`user/list/${store.state.auth_company}`);
}
//셀렉트박스 유저리스트 호출
function getSelectUserList() {
  return instance.get(`user/list/${store.state.auth_company}/selectUserList`);
}

//기존 pw 확인 하는 api 호출
function checkUserPw(data) {
  return instance.post(`user/pwCheck`, data);
}
function checkPhoneNumber(data) {
  return instance.post("user/checkPhone", data);
}
// pw 변경하는 api 호출
function modifyPassword(data) {
  return instance.put(`user/modifyPassword`, data);
}
function deleteAccount(data) {
  return instance.post("user/deleteAccount", data);
}
function postUser(data) {
  return instance.post(`user`, data);
}
function putUser(data) {
  return instance.put(`user`, data);
}

export {
  getSelectUserList,
  getUserByCompany,
  postUser,
  putUser,
  checkPhoneNumber,
  checkUserPw,
  modifyPassword,
  deleteAccount,
};
